import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import HeaderImage from "../components/header-image"
import Page from "../components/page"
import Section from "../components/section"
import MoreInfoTiles from "../components/more-info-tiles"
import colours from "../utils/colours"

const TermsPage = ({ location }) => {
  const data = useStaticQuery(graphql`
      {
          wpgraphql {
              pageBy(uri: "terms") {
                  termsPage {
                      title
                      description
                      headerImage{
                          sourceUrl
                          altText
                          imageFile {
                              childImageSharp {
                                  fluid (quality: 90) {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                  }
                  seo {
                      metaDesc
                      title
                  }
              }
          }
      }
  `)
  let seoData = data.wpgraphql.pageBy.seo
  let pageData = data.wpgraphql.pageBy.termsPage

  return(
    <Page
        seoTitle={ seoData.title }
        seoDescription={ seoData.metaDesc }
    >
      <HeaderImage
        image={ pageData.headerImage }
      />
      <Section>
          <h1 dangerouslySetInnerHTML={{ __html: pageData.title }}/>
          <p dangerouslySetInnerHTML={{ __html: pageData.description }}/>
      </Section>
      <Section backGColour={ colours.highlightGrey }>
          <MoreInfoTiles
            location={ location }
          />
      </Section>
    </Page>
  )
}
export default TermsPage
