import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { graphql, Link, useStaticQuery } from "gatsby"

let Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
`
let Tile = styled.div`
  width: 100%;
  height: 0;
  cursor: pointer;
  position: relative;
  display: inline-block;
  grid-column: span 6;
  padding-top: 50%;
  .gatsby-image-wrapper{
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;  
  }
  @media screen and (min-width: 750px){
    &:nth-of-type(1),
    &:nth-of-type(2){
      grid-column: span 3;
    }
    &:nth-of-type(3),
    &:nth-of-type(4),
    &:nth-of-type(5){
      grid-column: span 2;
      padding-top: 83.333%;
    } 
  }
`
let StyledLink = styled(Link)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  text-transform: uppercase;
`
let TileInner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.4);
  transition: all 0.3s ease-in-out;
  h3{
    margin: 0;
    font-size: 26px;
    position: absolute;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate( -50%, -50%);
    text-align: center;
  }
  &:hover{
    background-color: rgba(0,0,0,0);
  }
`

const MoreInfoTiles = ({ location }) => {
  const data = useStaticQuery(graphql`
      {
          wpgraphql {
              themeGeneralSettings {
                  themeOptions {
                      moreInfo {
                          link
                          title
                          image {
                              sourceUrl
                              altText
                              imageFile {
                                  childImageSharp {
                                      fluid {
                                          ...GatsbyImageSharpFluid_withWebp
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
  `)

  /* If homepage return first 5 tiles */
  let tiles
  if(location.pathname === '/' || location.pathname === '/privacy' || location.pathname === '/terms'){
    tiles = data.wpgraphql.themeGeneralSettings.themeOptions.moreInfo.slice(0, 5)
  }else{
    tiles = data.wpgraphql.themeGeneralSettings.themeOptions.moreInfo
  }

  return(
    <Container>
      { tiles.map(({ title, link, image }, index ) => {
          // Return items except current page tile
          if(link.replace(/\//g,'') !== location.pathname.replace(/\//g,'')){
            return (
              <Tile key={ index }>
                <StyledLink to={ link }>
                  <Img
                    fluid={ image.imageFile.childImageSharp.fluid }
                    alt={ image.altText }
                  />
                  <TileInner>
                    <h3 dangerouslySetInnerHTML={{ __html: title }}/>
                  </TileInner>
                </StyledLink>
              </Tile>
            )
          }

        })}

    </Container>
  )
}
export default MoreInfoTiles
